import React from "react";
import * as Styles from "./call-to-action.styles";

export const CallToAction = (props) => {
  return (
    <Styles.Wrapper image={props.image} to={props.to} title={props.title}>
      {props.children}
    </Styles.Wrapper>
  );
};

export const ExternalCallToAction = (props) => {
  return (
    <Styles.Wrapper
      as="a"
      image={props.image}
      href={props.href}
      rel="noopener noreferrer"
      target="_blank"
      title={props.title}
    >
      {props.children}
    </Styles.Wrapper>
  );
};

import styled from "styled-components";
import css from "@styled-system/css";
import { Link } from "gatsby";

export const Wrapper = styled(Link)`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 250px;
  text-decoration: none;
  width: 100%;

  ${({ image }) =>
    css({
      background: `url(${image}) 50% 50%/cover no-repeat`,
      borderRadius: [4],
      color: "foreground.primary",
      fontFamily: "heading",
      fontSize: [4],
      fontWeight: "semiBold",
    })}
`;

import React from "react";
import styled from "styled-components";
import { CallToAction, ExternalCallToAction } from "../call-to-action";
import { Content } from "../content";
import { Grid } from "../grid";
import { Spacer } from "../spacer";
import CoursesImage from "../../images/cta/background/courses.jpg";
import MRImage from "../../images/cta/background/mission-rabies.jpg";
import MRLogoImage from "../../images/cta/foreground/mission-rabies-logo.png";
import WVSImage from "../../images/cta/background/wvs.jpg";
import WVSLogoImage from "../../images/cta/foreground/wvs-logo.png";
import IPANLogoImage from "../../images/charities/ipan.jpg";
import MarchigLogoImage from "../../images/charities/marchig.jpg";
import DogsTrustLogoImage from "../../images/charities/dogs-trust.jpg";
import FBBLogoImage from "../../images/charities/fbb.jpg";
import WTGLogoImage from "../../images/charities/wtg.jpg";
import AWBILogoImage from "../../images/charities/awbi.png";

const Image = styled.img`
  width: 100%;
  height: auto;
  max-width: 150px;
`;

const ImageSmall = styled(Image)`
  max-width: 100px;
`;

const CharityWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ContentLinks = () => {
  return (
    <Content>
      <Grid columns={3}>
        <CallToAction image={CoursesImage} title="Programmes" to="/courses">
          Programmes
        </CallToAction>
        <ExternalCallToAction
          image={WVSImage}
          href="https://wvs.org.uk/"
          title="Worldwide Veterinary Service"
        >
          <Image alt="Worldwide Veterinary Service" src={WVSLogoImage} />
        </ExternalCallToAction>
        <ExternalCallToAction
          image={MRImage}
          href="http://www.missionrabies.com/"
          title="Mission Rabies"
        >
          <Image alt="Mission Rabies" src={MRLogoImage} />
        </ExternalCallToAction>
      </Grid>
      <Spacer height={3} />
      <Grid columns={5}>
        <CharityWrapper>
          <Image alt="IPAN" src={IPANLogoImage} />
        </CharityWrapper>
        <CharityWrapper>
          <Image alt="Marchig" src={MarchigLogoImage} />
        </CharityWrapper>
        <CharityWrapper>
          <ImageSmall alt="FBB" src={FBBLogoImage} />
        </CharityWrapper>
        <CharityWrapper>
          <ImageSmall alt="WTG" src={WTGLogoImage} />
        </CharityWrapper>
        <CharityWrapper>
          <Image alt="AWBI" src={AWBILogoImage} />
        </CharityWrapper>
      </Grid>
    </Content>
  );
};
